/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Components */
import {
  SEO, Container, Section, Article, Heading,
} from '../components/components';

/* Messages */
import * as messages from '../messages/montaz.messages';

const Montaz = () => (
  <>
    <SEO title={messages.title} description={messages.description} />
    <Container subPage>
      <Heading headingLevel="h1" title={messages.mainHeader} centered />
      {messages.content.map(({ header, article }) => (
        <Section key={article}>
          <Article>
            {header && <Heading headingLevel="h2" title={header} /> }
            <Markdown rehypePlugins={[rehypeRaw]} children={article} />
          </Article>
        </Section>
      ))}
    </Container>
  </>
);

export default Montaz;
