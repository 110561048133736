/* eslint-disable max-len */
export const title = 'Montaż materiałów reklamowych';
export const description = 'Wykonujemy prace związane z montowaniem banerów reklamowych na budynkach oraz ogrodzeniach. Oklejamy również folią monomeryczną witryny sklepowe, a także auta i przyczepy.';
export const mainHeader = 'Montaż materiałów reklamowych';

export const content = [
  {
    article: `<p class="thin">Zależy Ci na popularyzacji marki, firmy, konkretnego produktu bądź eventu? Trafiłeś zatem we właściwe miejsce.
    Zajmujemy się produkcją rozmaitych materiałów marketingowych, a także wykonujemy montaż reklamy, banerów i
    kasetonów świetlnych. Usługi obejmują również oklejanie witryn sklepowych oraz aut. Poznaj wszystkie nasze realizacje i
    wybierz preferowany sposób ekspozycji wydruków wielkoformatowych i nie tylko:</p>
    \n * instalacja banerów i siatek mesh na konstrukcjach, budynkach i ogrodzeniach,
    \n * montaż kasetonów, liter świetlnych i przestrzennych,
    \n * produkcja i umiejscawianie konstrukcji pod dowolne realizacje służące promocji,
    \n * montaż zadrukowanych elementów szklanych,
    \n * kompleksowy branding sklepów,
    \n * naklejanie fototapet na ścianach biur i mieszkań,
    \n * kompleksowy branding sklepów,
    \n * Oklejanie reklamami aut osobowych i dostawczych, przyczep, foodtrucków reklamami, a także witryn dowolnymi wydrukami wielkoformatowymi.
    \n **Co jeszcze oferujemy?**
    \n Przeprowadzamy wizje lokalne, na których robimy pomiary i zdjęcia miejsc oraz doradzamy w kwestii optymalnych
    rozwiązań, jeśli chodzi o umiejscawianie wszelkich materiałów służących promocji marki i produktów. Realizacja prac,
    takich jak montaż banerów, kasetonów, a także oklejanie witryn i aut, jest możliwa w nocy bądź dni wolne. Wszystko po
    to, aby nie kolidować z codziennym funkcjonowaniem sklepów, galerii handlowych czy też biur.
    \n Wycenę usług wykonujemy po otrzymaniu opisu prac. Poszczególne projekty wcielamy w życie na terenie Warszawy i
    całego województwa mazowieckiego. W przypadku większych realizacji możemy dotrzeć do dowolnego miasta w kraju.
    `,
  },
  // {
  //   article: `**Oferujemy montaż wyprodukowanych przez nas materiałów reklamowych, w tym:**
  //   \n * montowanie banerów i siatek mesh na konstrukcjach, budynkach i ogrodzeniach,
  //   \n * instalacja kasetonów, liter świetlnych i przestrzennych,
  //   \n * produkcja i montaż konstrukcji pod reklamy,
  //   \n * montaż zadrukowanych elementów szklanych,
  //   \n * wyklejanie witryn folią monomeryczną, OWV, mrożoną, ploterowaną,
  //   \n * naklejanie fototapet na ścianach biur i mieszkań,
  //   \n * kompleksowy branding sklepów,
  //   \n * oklejanie aut, przyczep, foodtrucków
  //   \n Wykonujemy wizje lokalne na których dokonujemy pomiarów i zdjęć miejsc oraz doradzamy optymalne rozwiązania montaży.
  //   \n Montaż 24H - Możliwość wykonania prac w nocy bądź dni wolne, niekolidujących w funkcjonowaniu sklepów, galerii, biur, sal konferencyjnych itp.
  //   \n Wycena montaży możliwa po otrzymaniu opisu prac do wykonania oraz ewentualnie dodatkowych zdjęć.
  //   \n Usługi wykonujemy na terenie mazowsza. Przy większy pracach możliwość dojazdu na terenie całego kraju.
  //   `,
  // },
];
